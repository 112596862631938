/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  top: -8px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 5px;
  -webkit-transition: .4s;
  transition: .4s;
}

.settings-dialog label {
 /* display: inline-block;
 width: 140px;
 text-align: right; */
}

input:checked + .slider {
  background-color: #f47920;;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f47920;;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

div.settings p{
  display: inline-block;
  margin-right: 5px;
}
div.settings label       { text-align:right; }

.show{
    display:block !important;
}

#shader-2{
    width: 100%;
    height: 100%;
    position: fixed;
    background: white;
    opacity: .8;
    display: none;
    /* position: absolute; */
    top: 0px;
    left: 0px;
    z-index: 11;
}
#shader-3{
    width: 100%;
    height: 100%;
    position: fixed;
    background: white;
    opacity: .8;
    display: none;
    /* position: absolute; */
    top: 0px;
    left: 0px;
    z-index: 11;
}

#invisaShader, #invisaShader2{
    width: 100%;
    height: 100%;
    position: fixed;
    display: none;
    top: 0px;
    left: 0px;
    z-index: 13;
}

.ok{
    text-decoration: none;
    display: block;
    background-color: #f47920;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    width:60px;
    margin:auto;
}

.ok:hover{
    color: darkorange;
    background-color: white;
    cursor:pointer;
}

#emoji-uni-search{
  padding:10px;
  width:90%;
}

.emoji-uni-option{
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  border-radius: 3px;
  margin: 1px;
  padding: 4px;
  font-size: 1.25em;
}

.emoji-uni-option:hover{
  border: 1px solid #aaaaaa;
  padding: 3px;
  cursor:pointer;
}

.emoji-option {
    text-decoration: none;
    display: inline-block;
    background-color: #595959;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    min-width:60px;
    padding:5px;
    margin:auto;
    margin-left:2.5px;
    margin-right:2.5px;
}

.emoji-option:hover{
    cursor:pointer;
    background-color:black;
}

.emoji-option.crowd-color:hover  svg path{
    fill: url(#image);
}

.emoji-dialog{
  position:fixed !important;
  top:25%;
}

.emoji-uni-dialog{
  /* position: absolute !important; */
  /* top: 0px !important; */
  transform: inherit !important;
  text-align: inherit !important;
  max-height:60vh;
  top: 15% !important;
  overflow-y: scroll;
}

.prompt-button{
    text-decoration: none;
    display: inline-block;
    background-color: #f47920;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    min-width:60px;
    padding:5px;
    margin:auto;
    margin-left:2.5px;
    margin-right:2.5px;
}

.prompt-button:hover{
    color: darkorange;
    background-color: white;
    cursor:pointer;
}

.close{
    position: absolute;
    font-size:28px;
    top: 10px;
    right: 10px;
    width: 28px;
}

.close:hover{
    cursor: pointer;
    color: red;
    width: 28px;
}

.close:hover{
    position: absolute;
    top: 10px;
    right: 10px;
}

#talkdust_dialog{
    border-radius: 10px;
    text-align: center;
    border-radius: 10px;
    background-color: white;
    padding: 20px 0px;
    margin-top: 10px;
    width: 80%;
    margin-left: 5%;
    display:none;
    position:absolute;
    left: 0;
    right: 0;
    top:10vh;
    z-index: 12;
    padding: 3em !important;
}

#talkdust_dialog.auth-dialog{
  padding: 1em!important;
}

.prompt-dialog{
  position:fixed !important;
}

#talkdust_overlay{
    border-radius: 10px;
    text-align: center;
    border-radius: 10px;
    background-color: white;
    padding: 20px 0px;
    margin-top: 10px;
    width: 90%;
    margin-left: 5%;
    display:none;
    position:fixed;
    left: 0;
    right: 0;
    top:50vh;
    transform: translate(0%, -50%);
    z-index: 15;
}

#talkdust_dialog iframe, #talkdust_overlay iframe{
  max-width:95%;
}

#talkdust_dialog ul, #talkdust_overlay ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#talkdust_dialog h1, #talkdust_dialog h2, #talkdust_overlay h1, #talkdust_overlay h2{
  font-family: poppins-black;
}

#talkdust_dialog p, #talkdust_overlay p{
    color: #383735;
}

#talkdust_dialog, #talkdust_overlay{
    box-shadow: 0px 0px 2px #312e2e;
}

#talkdust_dialog h2, #talkdust_overlay h2{
    color: #f47920;
}

#taldkust-prompt-bar{
  display:none;
  width: 100%;
  position: fixed;
  background: #383735;
  min-height: 60px;
  top: 0px;
  margin-top: 0px;
  z-index: 15;
  text-align: center;
}

#taldkust-prompt-bar p, #taldkust-prompt-bar div{
  display:inline-block;
  margin:5px;
  position: relative;
  top: 4px;
  line-height: 26px;
  color: #f47920;
}

#talkdust-prompt-bar p{
  color:#f47920;
}

#taldkust-prompt-bar div{
  border: 1px solid white;
  color:white;
  padding: 7px;
  border-radius: 5px;
}

#taldkust-prompt-bar div:hover{
  border: 1px solid #f47920;
  color:#f47920;
  cursor:pointer;
}


@media (min-width: 400px){
    #talkdust_dialog, #talkdust_overlay {
        padding:5px;
        padding-bottom: 15px;
        padding-left:15px;
        padding-right:15px;
        width:500px;
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
        /* box-shadow: 0px 0px 2px #312e2e; */
    }
}

@media (min-width: 680px){
    #talkdust_dialog, #talkdust_overlay {
        /* max-width: 75%; */
        width:500px;
    }
}

@media (min-width: 800px){
    /* #talkdust_dialog, #talkdust_overlay {
        max-width: 50%;
    } */
    /* .timeline-dialog{
      max-width:70% !important;
    } */
}


@media (min-width: 1080px){
    /* #talkdust_dialog {
        max-width: 33%;
    } */
    /* .timeline-dialog{
      max-width:67% !important;
    } */
}
