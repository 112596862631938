.switch {
  width: 60px;
  height: 34px;
  display: inline-block;
  position: relative;
  top: -8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: #ccc;
  border-radius: 5px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.slider:before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: #fff;
  border-radius: 5px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider {
  background-color: #f47920;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f47920;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

div.settings p {
  margin-right: 5px;
  display: inline-block;
}

div.settings label {
  text-align: right;
}

.show {
  display: block !important;
}

#shader-2, #shader-3 {
  width: 100%;
  height: 100%;
  opacity: .8;
  z-index: 11;
  background: #fff;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#invisaShader, #invisaShader2 {
  width: 100%;
  height: 100%;
  z-index: 13;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.ok {
  color: #fff;
  width: 60px;
  background-color: #f47920;
  border-radius: 3px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.ok:hover {
  color: #ff8c00;
  cursor: pointer;
  background-color: #fff;
}

#emoji-uni-search {
  width: 90%;
  padding: 10px;
}

.emoji-uni-option {
  border-radius: 3px;
  margin: 1px;
  padding: 4px;
  font-size: 1.25em;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.emoji-uni-option:hover {
  cursor: pointer;
  border: 1px solid #aaa;
  padding: 3px;
}

.emoji-option {
  color: #000;
  min-width: 60px;
  background-color: #595959;
  border-radius: 3px;
  margin: auto 2.5px;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.emoji-option:hover {
  cursor: pointer;
  background-color: #000;
}

.emoji-option.crowd-color:hover svg path {
  fill: url("#image");
}

.emoji-dialog {
  top: 25%;
  position: fixed !important;
}

.emoji-uni-dialog {
  max-height: 60vh;
  overflow-y: scroll;
  transform: inherit !important;
  text-align: inherit !important;
  top: 15% !important;
}

.prompt-button {
  color: #fff;
  min-width: 60px;
  background-color: #f47920;
  border-radius: 3px;
  margin: auto 2.5px;
  padding: 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.prompt-button:hover {
  color: #ff8c00;
  cursor: pointer;
  background-color: #fff;
}

.close {
  width: 28px;
  font-size: 28px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close:hover {
  cursor: pointer;
  color: red;
  width: 28px;
  position: absolute;
  top: 10px;
  right: 10px;
}

#talkdust_dialog {
  text-align: center;
  width: 80%;
  z-index: 12;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5%;
  padding: 20px 0;
  display: none;
  position: absolute;
  top: 10vh;
  left: 0;
  right: 0;
  padding: 3em !important;
}

#talkdust_dialog.auth-dialog {
  padding: 1em !important;
}

.prompt-dialog {
  position: fixed !important;
}

#talkdust_overlay {
  text-align: center;
  width: 90%;
  z-index: 15;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5%;
  padding: 20px 0;
  display: none;
  position: fixed;
  top: 50vh;
  left: 0;
  right: 0;
  transform: translate(0%, -50%);
}

#talkdust_dialog iframe, #talkdust_overlay iframe {
  max-width: 95%;
}

#talkdust_dialog ul, #talkdust_overlay ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#talkdust_dialog h1, #talkdust_dialog h2, #talkdust_overlay h1, #talkdust_overlay h2 {
  font-family: poppins-black;
}

#talkdust_dialog p, #talkdust_overlay p {
  color: #383735;
}

#talkdust_dialog, #talkdust_overlay {
  box-shadow: 0 0 2px #312e2e;
}

#talkdust_dialog h2, #talkdust_overlay h2 {
  color: #f47920;
}

#taldkust-prompt-bar {
  width: 100%;
  min-height: 60px;
  z-index: 15;
  text-align: center;
  background: #383735;
  margin-top: 0;
  display: none;
  position: fixed;
  top: 0;
}

#taldkust-prompt-bar p, #taldkust-prompt-bar div {
  color: #f47920;
  margin: 5px;
  line-height: 26px;
  display: inline-block;
  position: relative;
  top: 4px;
}

#talkdust-prompt-bar p {
  color: #f47920;
}

#taldkust-prompt-bar div {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 7px;
}

#taldkust-prompt-bar div:hover {
  color: #f47920;
  cursor: pointer;
  border: 1px solid #f47920;
}

@media (min-width: 400px) {
  #talkdust_dialog, #talkdust_overlay {
    width: 500px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 15px 15px;
  }
}

@media (min-width: 680px) {
  #talkdust_dialog, #talkdust_overlay {
    width: 500px;
  }
}

/*# sourceMappingURL=utils.4de70a66.css.map */
